import { defineStore } from 'pinia'
import { type Organization, type PatientStatusType } from '~/api'
import { computed, ref } from 'vue'
import { useSession } from '~/composables/useSession'
import { refDebounced } from '@vueuse/core'
import { useApi } from '~/composables/useApi'
import { useAsyncData } from '#imports'

/*
 * This is the main store for the webapp.
 * It contains global state that is used across the application.
 * Can be split into multiple stores later if needed.
 */
export const useMainStore = defineStore('main', () => {
  const selectedOrganization = ref<Organization | null>(null)
  const organizationsList = ref<Organization[]>([])
  const patientsListSearch = ref<string>('')
  const medicalCodes = ref<string[] | null>(null)
  const patientsListSearchDebounced = refDebounced(patientsListSearch, 400)

  const { hasPreDxViewerPermission, hasPreDxAnnotatorPermission, hasPreHxPermission } = useSession()
  const activeChart = ref<PatientStatusType>(undefined)

  const fetchOrganizations = async () => {
    const api = useApi()
    const { data: organizations } = await useAsyncData(
      'organizations',
      async () => await api.patient.getOrganizationList(),
    )
    if (organizations.value != null) {
      organizationsList.value = organizations.value
    }
  }

  const fetchMedicalCodes = async () => {
    const api = useApi()
    if (medicalCodes.value === null) {
      const { data: _medicalCodes } = await useAsyncData('medicalCodes', async () => await api.medicalCodes.list())
      medicalCodes.value = _medicalCodes.value ? _medicalCodes.value.map((code) => code.text) : null
    }
    return medicalCodes.value
  }

  const $reset = () => {
    if (hasPreHxPermission.value) {
      activeChart.value = undefined
    }
    if (hasPreDxViewerPermission.value) {
      activeChart.value = 'complete'
    }
    if (hasPreDxAnnotatorPermission.value) {
      activeChart.value = 'incomplete'
    }
  }
  $reset()

  const setActiveChart = async (chartType: PatientStatusType) => {
    activeChart.value = chartType
  }

  const isActiveChartComplete = computed(() => activeChart.value === 'complete')
  const isActiveChartIncomplete = computed(() => !isActiveChartComplete.value)
  const accountSetupNotFinished = computed(() => {
    return organizationsList.value?.length === 1 && organizationsList.value[0].name === 'Demo Org'
  })

  const isAddPatientDisabled = computed(() => {
    return organizationsList.value.some((org) => org.disableAddPatientForm)
  })
  return {
    accountSetupNotFinished,
    selectedOrganization,
    organizationsList,
    fetchOrganizations,
    patientsListSearch,
    patientsListSearchDebounced,
    activeChart,
    setActiveChart,
    isActiveChartIncomplete,
    isActiveChartComplete,
    $reset,
    isAddPatientDisabled,
    fetchMedicalCodes,
  }
})
